import { library } from '@fortawesome/fontawesome-svg-core'
import { faClock, faBatteryQuarter, faCheckCircle, faMapLocationDot, faRectangleXmark, faBars, faCircleCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faGoogle, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueGeolocation from 'vue-browser-geolocation'
import VueQrcodeReader from 'vue-qrcode-reader'
import VueSession from 'vue-session'
import VueTheMask from 'vue-the-mask'
import FBSignInButton from 'vue-facebook-signin-button'

import router from './router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './ml'
import Vue from 'vue'
import App from './App.vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

Vue.use(VueSession, { persist: true })
Vue.use(VueTheMask)
Vue.use(FBSignInButton)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
library.add(faCheckCircle, faClock, faBatteryQuarter, faFacebook, faGoogle, faWhatsapp, faMapLocationDot, faRectangleXmark, faBars, faCircleCheck, faXmark)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueGeolocation)
Vue.use(VueQrcodeReader)
Vue.config.productionTip = true
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places'
  }
})
Vue.component('google-maps', VueGoogleMaps.Map)

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  attachStacktrace: true
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
