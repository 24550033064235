<template>
  <div>
    <div id="qr_div" v-if="qr_code_on">
      <qrcode-stream @decode="onQrDecode" @init="onQrInit">
        <img id="qr-back" src="../assets/images/qr_fundo.png" alt="" />
        <font-awesome-icon class="btn-close-qr" icon="fa-solid fa-rectangle-xmark" v-on:click="close_scan_qr"/>
      </qrcode-stream>
    </div>
    <b-button
      id="scan_qr_code_button"
      v-if="cableType != null && !qr_code_on"
      v-on:click="scan_qr()"
      class="button scan-bt"
      variant="primary"
      v-text="$ml.get('home.take.scan_qr')"
    ></b-button>
    <p
      v-if="cableType != null"
      class="page-text"
      v-text="$ml.get('home.take.type_token')"
    ></p>
    <b-form-group v-if="cableType != null">
      <b-form-input
        id="token_input_"
        autocomplete="off"
        type="text"
        maxlength="4"
        v-model="token"
        :placeholder="$ml.get('home.take.terminal_token')"
      ></b-form-input>
    </b-form-group>
  </div>

</template>

<script>

export default {

  name: 'TokenSelection',

  props: {
    cableType: Number,
    confirm_rent: Function
  },

  data () {
    return {
      qr_code_on: false,
      token: ''
    }
  },

  mounted () {},

  watch: {
    token (newValue) {
      this.$emit('token', newValue)
    }
  },

  methods: {

    onQrDecode: function (result) {
      this.token = result.split('qr/')[1]
      this.qr_code_on = false
      this.confirm_rent(this.token)
    },

    scan_qr: function () {
      this.qr_code_on = true
    },

    close_scan_qr: function () {
      this.qr_code_on = false
    },

    onQrInit: async function (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.handle_error('ERROR: you need to grant camera access permisson')
        } else if (error.name === 'NotFoundError') {
          this.handle_error('ERROR: no camera on this device')
        } else if (error.name === 'NotSupportedError') {
          this.handle_error('ERROR: secure context required (HTTPS, localhost)')
        } else if (error.name === 'NotReadableError') {
          this.handle_error('ERROR: is the camera already in use?')
        } else if (error.name === 'OverconstrainedError') {
          this.handle_error('ERROR: installed cameras are not suitable')
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.handle_error('ERROR: Stream API is not supported in this browser')
        }
      }
    }

  }

}

</script>

<style scoped lang="scss">

@import "@/main.scss";

.page-text {
  font-size: 14px;
}

.scan-bt {
  background-color: $color-energy-light-blue  !important;
  margin: 20px auto;
}

#qr_div {
  margin-bottom: 20px;
}

#qr-back {
  width: 100%;
  height: 100%;
}

.btn-close-qr {
  font-size: 33px;
  z-index: 99999;
  color: #fff;
  position: absolute;
  top: 8px;
  right: 12px;
  cursor: pointer;
}

</style>
