<template>

  <section id="login-back" class="inner-section">
  <img src="../../assets/images/logo.png" alt="" id="logo" />
  <div id="login-div" style="background: #259df2">

    <div class="vue-template" style="text-align: center;">
      <div style="text-align: center;">
        <div>
          <h3 class="navbar-text mb-0" v-text="$ml.get('login.sign_in')"></h3>
        </div>

        <div class="error_container">
            <p v-if="error" class="errors">
                <b>{{ error }} </b>
            </p>
        </div>

        <div class="mt-4 mb-0" v-for="(_, index) in code" style="display: inline-flex; gap: 3px;" :key="index">
          <input
            :id="'id_' + index"
            style="font-size: xx-large; width: 40px; height: 50px; text-align: center; border-radius: 5px; border: none;"
            maxlength=VALIDATION_CODE_LENGTH
            required
            v-model="code[index]"
            type="text"
            autocapitalize="none"
            @input="onInput(index, $event)"
            @keydown="onKeyDown(index, $event)"
            @focus="onFocus($event)"
            @click="onClick($event)"
            ref="inputs"
          >
        </div>
        <h6 class="mt-0 mb-5" v-text="$ml.get('login.verify_mail')"></h6>

        <div style="width: 250px; margin: auto;">
          <button id="validate_button" v-on:click="validateCode" class="btn btn-primary btn-lg btn-block">Validar</button>
        </div>

        <div class="mt-3" style="width: 250px; margin: auto;">
          <button v-on:click="routeSingUp" class="btn btn-primary btn-lg btn-block">Reenviar email</button>
        </div>
      </div>

      <div class="mt-3 clearfix" id="account_options">
        <p class="register-account text-center mt-2 mb-4">

          <span>
            <router-link class="link" to="/login-social" v-text="$ml.get('login.register_social')"></router-link>
          </span>

        </p>
        </div>

    </div>

  </div>

</section>
</template>

<script>

import axios from 'axios'
import API from '../../services/api'
import { VALIDATION_CODE_LENGTH } from '@/constants.js'
import * as Sentry from '@sentry/vue'

export default {
  name: 'code-validation',
  props: [],
  data: function () {
    return {
      error: null,
      code: new Array(VALIDATION_CODE_LENGTH).fill('')
    }
  },
  computed: {},
  mounted: function () { },
  methods: {

    onInput (index, event) {
      if (!event) {
        return
      }
      if (event.target.value.length === 1) {
        if (index === VALIDATION_CODE_LENGTH - 1) {
          this.$refs.inputs[0].focus()
        } else {
          this.$refs.inputs[index + 1].focus()
        }
      } else {
        this.spreadString(event.target.value, index)
      }
    },

    onKeyDown (index, event) {
      if (event && event.keyCode === 8 && index > 0 && event.target.value === '') {
        this.code[index] = ''
        this.$refs.inputs[index - 1].focus()
      }

      if (event && event.keyCode === 46 && index < this.code.length - 1 && event.target.value === '') {
        this.code[index] = ''
        this.$refs.inputs[index + 1].focus()
      }
    },

    onFocus (event) {
      event && event.target && event.target.select()
    },

    onClick (event) {
      event && event.target && event.target.select()
      if (this.error) {
        this.error = null
      }
    },

    fullCode (code) {
      code.forEach(element => element.trim())
      return code.every(item => item !== '')
    },

    spreadString (str, index) {
      const flatStr = str.split('').flat()
      for (let i = 0; i < flatStr.length; i++) {
        const element = flatStr[i]
        if (index + i < VALIDATION_CODE_LENGTH) {
          this.code[index + i] = element
          this.$refs.inputs[index + i].focus()
        }
      }
    },

    removeFocus () {
      for (let index = 0; index < VALIDATION_CODE_LENGTH; index++) {
        this.$refs.inputs[index].blur()
      }
    },

    validateCode: async function () {
      if (this.code.length !== VALIDATION_CODE_LENGTH) {
        return console.log('passe o código')
      }
      const validationCode = this.code.join('')

      try {
        const response = await API.get(`/customer/validate_code/${validationCode}`)

        if (response.data.success) {
          const token = response.data.token
          this.$session.set('token', token)
          axios.defaults.headers.common['Authorization'] = token
          return this.$router.push(
            '/home'
          )
        }

        this.error = response.data.message
      } catch (error) {
        this.error = error
        console.log(error)
        Sentry.captureException(error)
      } finally {
        if (this.fullCode(this.code)) {
          this.code = new Array(VALIDATION_CODE_LENGTH).fill('')
        }
      }
    },

    routeSingUp: function () {
      this.$router.push(
        '/sing-up'
      )
    }
  },

  watch: {
    code (newValue, _) {
      if (this.fullCode(newValue)) {
        this.removeFocus()
      }
    }
  }
}

</script>
<style scoped lang="scss">

@import "../../main.scss";

#login-back {
  background: url("../../assets/images/login_back.jpg");
  background-size: cover;
  text-align: center;
  height: 100vh;
}

#logo {
  width: 250px;
  margin: 60px auto;
}

#login-div {
  padding: 30px;
  color: white;
  font-size: 17px;
  clear: both;
  display: flex;
  flex-direction: column;
}

label {
  font-weight: 500;
}

#account_options {
  text-align: center;
}

.link {
  color: #fff;
  text-decoration: underline;
  font-weight: bold;
}

#app input {
  width: 30px;
  height: 30px;
  font-size: 24px;
  text-align: center;
  margin: 5px;
  caret-color: transparent;
}

.error_container .errors {
  color: #ff0000;
  font-size: 0.9em;
  text-align: center;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 5px;
}

</style>
