<template>
  <div>
    <p>
      <b>
        <span
          class="page-title"
          v-text="$ml.get('home.take.take_select')"
        ></span>
      </b>
      <span
        class="page-title"
        v-text="$ml.get('home.take.take_info')"
      ></span>
    </p>
    <b-container class="bv-example-row">
      <b-row>
        <b-col cols="4" class="cable-type">
          <img
            id="cable_lightning_button"
            v-bind:class="{ cableActive: cableType === 0 }"
            class="cable-img"
            src="../assets/images/cables/apple.png"
            v-on:click="select_cable(0)"
            alt="imagem conector Apple Lightning"
          />
          <p>Apple Lightning</p>
        </b-col>
        <b-col cols="4" class="cable-type">
          <img
            id="cable_micro_usb_button"
            v-bind:class="{ cableActive: cableType === 1 }"
            class="cable-img"
            src="../assets/images/cables/micro.png"
            v-on:click="select_cable(1)"
            alt="imagem conector Micro USB"
          />
          <p>Micro USB</p>
        </b-col>
        <b-col cols="4" class="cable-type">
          <img
            id="cable_type_c_button"
            v-bind:class="{ cableActive: cableType === 2 }"
            class="cable-img"
            src="../assets/images/cables/c.png"
            v-on:click="select_cable(2)"
            alt="imagem conector USB tipo C"
          />
          <p>USB-C</p>
        </b-col>
      </b-row>
    </b-container>
  </div>

</template>

<script>

export default {
  /* global fbq */

  name: 'SelectCableType',

  data () {
    return {
      cableSelection: true,
      cableType: null
    }
  },

  mounted () {},

  methods: {
    select_cable: function (type) {
      this.cableType = type
      if (this.cableSelection) {
        fbq('trackCustom', 'cableSelection')
        this.cableSelection = false
      }
      this.$emit('cableType', this.cableType)
    }
  }

}

</script>

<style scoped lang="scss">

@import "@/main.scss";

.cable-img {
  width: 100%;
  border: 1px solid #424242;
  border-radius: 100px;
  margin-bottom: 20px;
}

.cable-type {
  text-align: center !important;
  font-size: 12px;
}

.cableActive {
  border: 3px solid $color-energy-orange  !important;
}

</style>
