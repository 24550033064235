<template>
  <b-modal
    class="modalzin"
    v-if="plans"
    size="sm"
    ref="priceTable"
    :title="$ml.get('home.take.table_title')"
    @hide="hidePriceTableModal()"
  >
    <span v-text="$ml.get('home.take.text_table_redraw')"></span>
    <div style="margin-top: 12px; font-weight: 500">
      <span v-text="$ml.get('home.take.text_table_parks')"></span>
    </div>
    <div>
      <span v-text="$ml.get('home.take.text_table_charges_only')"></span>
      <span class="stay-same-line">
        <span
          class="amount-description-color"
          v-text="this.plan_daily.description"
        ></span>
        <span class="amount-description-color">s</span>
      </span>
      <span v-text="$ml.get('home.take.of')"></span>
      <span class="stay-same-line">
        <span class="amount-description-color">R$</span>
        <span
          class="amount-description-color"
          v-text="this.plan_daily.amount.reais"
        ></span>
        <span class="amount-description-color">,</span>
        <span
          class="amount-description-color"
          v-text="this.plan_daily.amount.centavos"
        ></span> </span
      >.
    </div>
    <div style="margin-top: 12px; font-weight: 500">
      <span v-text="$ml.get('home.take.text_table_other_locations')"></span>
    </div>
    <div>
      <span v-text="$ml.get('home.take.text_table_ischarged')"></span>
      <span class="stay-same-line">
        <span class="amount-description-color">R$</span>
        <span
          class="amount-description-color"
          v-text="this.plan_4_hours.amount.reais"
        ></span>
        <span class="amount-description-color">,</span>
        <span
          class="amount-description-color"
          v-text="this.plan_4_hours.amount.centavos"
        ></span>
      </span>
      <span v-text="$ml.get('home.take.by')"></span>
      <span
        class="amount-description-color"
        v-text="this.plan_4_hours.description"
      ></span>

      <span v-text="$ml.get('home.take.text_table_after_period')"></span>
      <span class="stay-same-line">
        <span
          class="amount-description-color"
          v-text="this.plan_daily.description"
        ></span>
        <span class="amount-description-color">s</span>
      </span>
      <span v-text="$ml.get('home.take.of')"></span>
      <span class="stay-same-line">
        <span class="amount-description-color">R$</span>
        <span
          class="amount-description-color"
          v-text="this.plan_daily.amount.reais"
        ></span>
        <span class="amount-description-color">,</span>
        <span
          class="amount-description-color"
          v-text="this.plan_daily.amount.centavos"
        ></span>
        .
      </span>
    </div>

    <p class="footnote" v-text="$ml.get('home.take.table_footnote')"></p>
    <div slot="modal-footer">
      <b-btn
        v-on:click="$refs.priceTable.hide()"
        class="button"
        v-text="$ml.get('home.take.understand')"
      ></b-btn>
    </div>
  </b-modal>

</template>

<script>

import '@/main.scss'
import axios from 'axios'
import * as Sentry from '@sentry/vue'

export default {

  name: 'PriceTableModal',

  props: {
    showPriceTableModal: {
      type: Boolean,
      default: false
    }
  },

  async mounted  () {
    this.getPlans()
    this.openPriceTableModal()
  },

  data () {
    return {
      plans: null,
      plan_4_hours: null,
      plan_daily: null,
      mobile_api_url: process.env.VUE_APP_MOBILE_API
    }
  },

  methods: {

    openPriceTableModal () {
      const tryOpenModal = () => {
        try {
          this.$refs.priceTable.show()
        } catch (error) {
          setTimeout(tryOpenModal, 200)
        }
      }
      tryOpenModal()
    },

    hidePriceTableModal () {
      this.$emit('hidePriceTableModal')
    },

    async getPlans () {
      try {
        const response = await axios.get(this.mobile_api_url + 'plan')
        if (response.data) {
          this.plans = response.data
          this.plan_4_hours = this.plans.on_demand.prices.find(
            price => price.internal_ref === 'four_hours'
          )
          this.plan_daily = this.plans.on_demand.prices.find(
            price => price.internal_ref === 'daily'
          )
          return
        }
        console.log('Failed to get plans')
        Sentry.captureMessage(`Failed to get plan ${JSON.stringify(response)}, calling #getPlans()`)
      } catch (error) {
        Sentry.captureException(error)
        console.log(error)
      }
    }

  }

}

</script>

<style scope lang="scss">

@import '@/main.scss';

.stay-same-line{
  overflow: hidden;
  white-space: nowrap;
}

.amount-description-color{
  color: $color-energy-orange;
  display: inline-block;
}

.footnote{
  font-size: 12px;
  margin-top: 13px;
}

</style>
