<template>

    <b-modal
      class="modalzin"
      ref="modalSaveEvaluation"
      :title="$ml.get('home.take.battery_not_removed')"
      :hide-close-button="true"
      :no-close-on-backdrop="true"
    >

      <div slot="modal-header-close"></div>
      <p class="d-flex justify-content-center" style="font-size: large;" v-html="modal_error"></p>

      <div>
        <p class="d-flex flex-wrap justify-content-center">
          <button
            size="sm"
            v-for="(tag, index) in evaluationTags"
            :key="index"
            @click="toggleTagState(tag.id)"
            :class="[tag.active ? 'errorTag active' : 'errorTag']"
          >
            <div style="white-space: nowrap; font-size: small;">{{ tag.tag }}</div>
          </button>
        </p>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-button class="float-left" style="font-size: 1.0em;" @click="hideEvaluationModal" v-text="$ml.get('home.take.skip')"></b-button>
          <b-button class="float-right" @click="saveTags" variant="primary" v-text="$ml.get('home.take.send')"></b-button>
        </div>
      </template>

    </b-modal>
</template>

<script>

import API from '@/services/api'
import { TAKE_BATTERY_ERROR } from '@/constants.js'

export default {

  name: 'EvaluationModal',

  props: {
    modal_error: String,
    handle_error: Function,
    transactionId: Number
  },

  data () {
    return {
      evaluationTags: [],
      showThanksModal: false,
      uniqueTag: true
    }
  },

  mounted () {
    console.log('Bateria não foi retirada')
    this.getTags(TAKE_BATTERY_ERROR)
    this.handle_error(this.$ml.get('home.take.what_happened'), this.$refs.modalSaveEvaluation)
  },

  methods: {

    hideEvaluationModal () {
      this.$refs.modalSaveEvaluation.hide()
      this.$emit('resetBoolean', false)
      this.$emit('openThanks', false)
    },

    hideEvaluationModalAndOpenThanks () {
      this.$refs.modalSaveEvaluation.hide()
      this.$emit('resetBoolean', false)
      this.$emit('openThanks', true)
    },

    hideThanksModal () {
      this.$refs.modalFeedbackThanks.hide()
      this.showThanksModal = false
    },

    toggleTagState (tagId) {
      this.evaluationTags = this.evaluationTags.map(tag => {
        if (tag.id === tagId) {
          return {
            id: tag.id,
            tag: tag.tag,
            active: this.uniqueTag ? true : !tag.active
          }
        } else {
          return {
            id: tag.id,
            tag: tag.tag,
            active: this.uniqueTag ? false : tag.active
          }
        }
      })
    },

    async getTags (status) {
      this.evaluationTags = null
      const response = await API.get(`evaluation/${status}/tags`)
      this.uniqueTag = response.data.unique_tag
      this.evaluationTags = response.data.tags.map(tag => {
        return {
          id: tag.id,
          tag: this.$session.get('lang') === 'en' ? tag.tag_en : tag.tag,
          active: false
        }
      })
    },

    async saveTags () {
      if (!this.transactionId) {
        console.log('Transaction id not defined')
        this.hideEvaluationModal()
      }

      const tagIds = []
      this.evaluationTags.forEach(tag => {
        if (tag.active === true) {
          tagIds.push(tag.id)
        }
      })

      try {
        const data = {
          transaction_id: this.transactionId,
          tag_ids: tagIds
        }
        const response = await API.post('/transaction/evaluation', data)
        if (!response) {
          console.log('Problem saving evaluation')
          return
        }

        if (!response.data.success) {
          console.log(response.data.message)
          return
        }
      } catch (error) {
        this.hideEvaluationModal()
        console.error('Error saving evaluation:', error)
        this.handle_error(error)
        return
      }

      this.hideEvaluationModalAndOpenThanks()
    }

  }
}
</script>

<style scoped lang="scss">

@import "@/main.scss";

.errorTag {
  border: 1px solid $color-energy-gray;
  border-radius: 35px;
  background-color: transparent;
  color: #000000;
  padding: 0.3rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
  margin-right: 10px;;
  width: fit-content;
}

.errorTag.active {
  border-color: #fff;
  background-color: $color-energy-blue;
  color: #fff;
}
</style>
