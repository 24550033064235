export default {
  name: 'qr',
  components: {},
  props: [],
  data () {
    return {}
  },
  computed: {},
  mounted: function () {
    const rentbrellaTokens = ['7008', '7029', '7106', '7007', '7047']
    const redirectToRentbrella = function (token) {
      window.location.href = 'http://9dejulho.rentbrella.com/qr/' + token
    }
    let type = this.$route.path.split('/')[1]
    if (type === 'qr') {
      let token = this.$route.path.split('/')[2]
      if (rentbrellaTokens.includes(token)) {
        redirectToRentbrella(token)
        return
      }
      this.$session.set('terminal_token', token)
      this.$router.push({
        name: 'app',
        params: {}
      })
    }
  },
  methods: {}
}
