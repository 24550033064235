import API from '../../services/api'
import OpenEmailModal from '@/components/OpenEmailModal'
import PriceTableModal from '@/components/PriceTableModal'

export default {
  name: 'sing_up',
  components: {
    OpenEmailModal,
    PriceTableModal
  },
  props: [],
  data: function () {
    return {
      style: {
        bg: '#016ab3',
        secondary_bg: '#ffffff'
      },
      errors: [],
      message: null,
      input: {
        email: '',
        userTerms: false
      },
      openEmailModal: false,
      showPriceTableModal: false
    }
  },
  computed: {},

  mounted () {
    if (this.$route.query.message) {
      this.message = this.$route.query.message
    }
  },
  methods: {
    handleShowPriceTableModal (data) {
      this.showPriceTableModal = data
    },

    handleOpenEmailModal: function () {
      this.openEmailModal = false
    },

    singUp: function (e) {
      const validateForm = (input) => {
        this.errors = []
        this.message = null
        const { email: mail, userTerms } = input

        const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        if (mailformat.test(mail) === false) {
          this.errors.push({ email: ['sing_up.invalid_email'] })
        }
        if (!mail) {
          this.errors.push({ email: ['sing_up.required_email'] })
        }
        if (!userTerms) {
          this.errors.push({ userTerms: ['sing_up.mandatory_user_terms'] })
        }
        return this.errors.length === 0
      }

      if (!validateForm(this.input)) {
        e.preventDefault()
        return
      }
      const { email } = this.input
      API.post('customer/register', { email }).then(res => {
        if (res.data.success === true) {
          this.openEmailModal = true
          return
        }

        this.message = res.data.message
      }).catch(error => {
        console.log(error)
        this.message = error
      })

      e.preventDefault()
    }
  }
}
