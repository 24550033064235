/* global FB */
/* global google */
/* global fbq */
import * as Sentry from '@sentry/vue'
import axios from 'axios'
import fbIcon from '../../assets/images/facebook_icon.png'
import PriceTableModal from '@/components/PriceTableModal'
export default {
  name: 'login-social',
  components: {
    PriceTableModal
  },
  props: [],
  data: function () {
    return {
      fb_icon: fbIcon,
      locale: '',
      showPriceTableModal: false
    }
  },
  computed: {},
  mounted: function () {
    if (this.$route.query.token) {
      this.token = this.$route.query.token
      this.$session.set('token', this.token)
      axios.defaults.headers.common['Authorization'] = this.token
      this.$router.push({
        name: 'home',
        params: {}
      })
    }

    fbq('track', 'ViewContent')
    let googleScript = document.createElement('script')
    googleScript.src = 'https://accounts.google.com/gsi/client'
    googleScript.defer = true
    googleScript.async = true
    document.body.appendChild(googleScript)

    window.fbAsyncInit = function () {
      FB.init({
        appId: process.env.VUE_APP_FACEBOOK_ID,
        cookie: true,
        xfbml: true,
        status: true,
        version: process.env.VUE_APP_FACEBOOK_VERSION
      })
    };

    (function (d, s, id) {
      let js
      let fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = '//connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')

    const decodeJwtResponse = function (token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))

      return JSON.parse(jsonPayload)
    }

    const googleLoginResponse = function (response) {
      const responsePayload = decodeJwtResponse(response.credential)
      axios.post(process.env.VUE_APP_MOBILE_API + 'social/login', {
        email: responsePayload.email,
        name: responsePayload.name,
        social_id: responsePayload.sub,
        from_social: 'google',
        auto_confirm: true
      })
        .then(
          res => {
            fbq('track', 'CompleteRegistration', { content_name: 'google' })
            if (res.data.success) {
              self.$session.set('token', res.data.token)
              axios.defaults.headers.common['Authorization'] = res.data.token
              self.$router.push({
                name: 'home',
                params: {}
              })
            } else {
              Sentry.captureMessage(`Failed to google login ${JSON.stringify(res)}, calling #google_login()`)
            }
          },
          error => {
            Sentry.captureException(error)
          }
        )
    }

    this.locale = this.$ml.get('login.locale')
    const buttonConfiguration = {
      theme: 'outline', size: 'large', width: 270, locale: this.locale
    }
    const self = this
    window.onload = function () {
      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: googleLoginResponse,
        auto_select: false
      })
      google.accounts.id.renderButton(
        document.getElementById('btn-google'),
        buttonConfiguration // customization attributes
      )
      google.accounts.id.prompt() // also display the One Tap dialog
    }
    window.onpopstate = function () {
      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: googleLoginResponse,
        auto_select: false
      })
      google.accounts.id.renderButton(
        document.getElementById('btn-google'),
        buttonConfiguration // customization attributes
      )
      // google.accounts.id.prompt() // also display the One Tap dialog
    }
  },
  methods: {
    handleShowPriceTableModal (data) {
      this.showPriceTableModal = data
    },

    handleCampaign: function () {
      const campaignUrl = this.$session.get('campaignUrl')
      if (campaignUrl) {
        this.$router.push(campaignUrl)
      }
    },
    onFacebookSignInSuccess (response) {
      if (response.authResponse) {
        if (response.authResponse.grantedScopes.includes('email')) {
          this.handleFacebookUserData(response.authResponse.userID)
        } else {
          this.reRequestEmailPermission()
        }
      }
    },

    handleFacebookUserData (userID) {
      FB.api('me?fields=name,email', user => {
        axios.post(process.env.VUE_APP_MOBILE_API + 'social/login', {
          name: user.name,
          email: user.email,
          social_id: userID,
          from_social: 'facebook',
          auto_confirm: true
        })
          .then(res => {
            if (res.data.success) {
              fbq('track', 'CompleteRegistration', { content_name: 'facebook' })
              this.$session.set('token', res.data.token)
              axios.defaults.headers.common['Authorization'] = res.data.token
              this.$router.push({ name: 'home', params: {} })
            } else {
              Sentry.captureMessage(`Failed to login facebook ${JSON.stringify(user)}, calling #facebook_login()`)
            }
          })
          .catch(error => {
            Sentry.captureException(error)
          })
      })
    },

    reRequestEmailPermission () {
      FB.login(response => {
        if (response.authResponse && response.authResponse.grantedScopes.includes('email')) {
          this.handleFacebookUserData(response.authResponse.userID)
        } else {
          console.error('User declined email permission again.')
        }
      }, { scope: 'email', auth_type: 'rerequest' })
    },

    onFacebookError: function (response) {
      Sentry.captureMessage(`Failed to facebook login ${JSON.stringify(response)}, calling #facebook_login()`)
    }
  }
}
