<template>
  <b-modal
    class="modalzin"
    ref="openEmailModal"
    size="sm"
    :title="$ml.get('sing_up.title_open_mail_modal')"
    :no-close-on-backdrop="true"
    @hidden="redirect"
  >
    <div class="my-4 d-flex justify-content-center" v-text="$ml.get('sing_up.email_verification')">
    </div>

    <div class="w-100" slot="modal-footer">
      <b-button id="email_verification_modal_close_button" class="float-right" variant="primary" style="font-size: 1.0em;" @click="hideModal" v-text="$ml.get('home.take.close')"></b-button>
    </div>
  </b-modal>
</template>

<script>

export default {

  name: 'OpenEmailModal',

  mounted () {
    this.open_modal(this.$refs.openEmailModal)
  },

  methods: {

    open_modal: function (modal) {
      try {
        modal.show()
      } catch (error) {
        setTimeout(() => {
          modal.show()
        }, 300)
      }
    },

    hideModal () {
      this.$refs.openEmailModal.hide()
    },

    redirect () {
      this.$emit('openEmailModal', false)
      this.$router.push(
        '/code-validation'
      )
    }
  }

}
</script>

<style scoped lang="scss">
 @import "@/main.scss";

  .modal-header,
  .modal-body,
  .modal-footer,
  .modal-title,
  .modalzin {
    color: #424242 !important;
  }

</style>
