<template>
  <b-modal
    class="modalzin"
    ref="modalFeedbackThanks"
    :no-close-on-backdrop="false"
  >

    <div slot="modal-header-close"></div>

    <div class="d-flex justify-content-center" v-text="$ml.get('home.take.feedback_thanks')"></div>

    <div slot="modal-footer">
      <b-button variant="primary" style="font-size: 1.0em;" @click="hideThanksModal" v-text="$ml.get('home.take.close')"></b-button>
    </div>
  </b-modal>
</template>

<script>

export default {

  name: 'ThanksModal',

  props: {
    handle_error: Function
  },

  mounted () {
    this.handle_error('', this.$refs.modalFeedbackThanks)
  },

  methods: {

    hideThanksModal () {
      this.$refs.modalFeedbackThanks.hide()
      this.$emit('openThanks', false)
    }
  }

}
</script>

<style scoped lang="scss"> @import "@/main.scss" </style>
