import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'

Vue.use(MLInstaller)

export default new MLCreate({
  initial: 'portuguese',
  save: process.env.NODE_ENV === 'production',
  languages: [
    new MLanguage('english').create({
      confirm_account: {
        welcome: 'Account confirmation',
        confirmation_successful: 'Your account has succesfuly confirmed',
        confirmation_failed: 'An error ocurred in your account confirmation',
        waiting: 'Waiting for account confirmation...',
        unspected_error: 'Unspected error occurred'
      },
      login: {
        battery_almost_over: 'BATTERY ALMOST OVER?!',
        fast_login: 'LOG IN FOR QUICK RENT',
        or: 'OR',
        create_account: 'CREATE NEW ACCOUNT',
        sign_in: 'Log in with your account',
        sign_in_button: 'Login',
        email_address: 'Email Address',
        password: 'Password',
        forgot_password: 'Forgot Password?',
        dont_have_account: 'Don\'t have an account?',
        register_email: 'Sing up with email',
        register_social: 'Sing up with social network',
        register_with_email: 'Access with email',
        login_with_code: 'Already have a code?',
        click_here: 'Click click_here',
        facebook_btn_text: 'Continue with Facebook',
        locale: 'en_US',
        whatsapp_msg: 'https://api.whatsapp.com/send/?phone=5521967445033&text=Hi!%20I%20need%20help%20-%20E2G',
        find_us: 'Find us ',
        title_rent: 'Rent a Power Bank',
        verify_mail: '*Check your inbox mail for the code. If not found, check on the spam folder'
      },
      sing_up: {
        submit: 'Access with email',
        sing_up: 'Sing Up',
        email_address: 'Email',
        password: 'Password',
        password_confirmation: 'Password Confirmation',
        agree_user_terms: ' I agree with the user terms',
        user_terms: 'User terms',
        already_have_account: 'Already have an account with your email?',
        already_have_social_account: 'Sing up with social network',
        mandatory_user_terms: 'You must agree with the user terms',
        password_doesnt_match: 'The password confirmation does not match the password',
        invalid_email: 'Invalid email',
        required_email: 'Email is required',
        email_verification: 'Verify your email to access your account. If not found, check on the spam folder',
        title_open_mail_modal: 'Validation'
      },
      forgot_password: {
        forgot_password: 'Forgot Password?',
        email: 'Email',
        remember: 'Submit',
        success: 'We have sent you an email with a link to reset your password',
        failed: 'We could not find a user with this email address',
        redirect_msg: 'You will be redirected to the login page in 5 seconds',
        invalid_email: 'Invalid email',
        required_email: 'Email is required'
      },
      map: {
        place_open: 'Open',
        place_closed: 'Closed',
        available_bateries: 'available bateries',
        available_slots: 'available slots',
        close_hour: 'Closes at',
        open_hour: 'Opens at',
        hour: 'en',
        my_location: 'My Location',
        phone: 'SMARTPHONE',
        ebike: 'E-BIKE',
        all: 'ALL',
        terminal_type: 'Terminals for: '
      },
      home: {
        take: {
          add_card: 'REGISTER YOUR CREDIT CARD',
          no_charge:
            'Register your credit card in order to rent the battery.',
          card_number: 'Credit Card Number',
          valid_through: 'Valid Through',
          name: 'Name',
          passport: 'Passport',
          use_passport: 'Use Passport',
          use_cpf: 'Use CPF',
          take_select: 'Select cable type, ',
          take_info: 'it is already built into the battery.',
          cable_type: 'Choose the Cable Type',
          scan_qr: 'Scan QR Code',
          type_token: 'Or enter the Token Located on Terminal Display',
          terminal_token: 'Terminal Token',
          take_battery: 'TAKE BATTERY',
          agreed: 'When you TAKE BATTERY you agree with the prices showed ',
          price_table: 'Price List',
          credit_cards: 'CREDIT CARDS',
          manage: 'Manage your payment methods',
          standard: 'Standard',
          delete: '',
          delete_title: 'Delete Credit Card',
          delete_text: 'Delete card ending in ',
          cancel: 'CANCEL',
          ok: 'OK',
          just_ok: 'OK',
          standard_title: 'Standard Credit Card',
          standard_text: 'Set as Standard the credit card ending in ',
          set_standart_credit_card_not_possible: 'An error occurred while updating the default payment method. Please try again',
          proccessing: 'Processing Rent',
          slot: 'PUSH, then PULL the battery indicated by the blinking LED',
          charger: "Charger's App must be indicating that the battery is ready to go",
          verify_terminal: 'Checking Terminal',
          register_card: 'Registering Credit Card',
          far:
            'We notice that the requested terminal is far from your device. Are you sure you want to continue?',
          credit_reservation_1:
            'A reservation of ',
          credit_reservation_2:
            ' will be held on your credit card. In some cases, when free time is offered we will free your credit card hold if you return the battery before the end of this free time.',
          dont_ask: "Don't ask again",
          campaign:
            'By using the promotional free time you agree to receive information from the sponsor.',
          no_free: 'Rent without the sponsored free time',
          update: 'Updating Permissions',
          on_demand: 'RENT ON DEMAND',
          subscription: 'SUBSCRIPTION PLANS',
          understand: 'I UNDERSTOOD',
          free_time_title: 'Next Rent',
          free: 'free',
          sponsored_by: 'Sponsored By',
          at: 'at',
          only_at: "To access all Terminal's Location, ",
          click_here: 'click here!',
          other_terminals: 'Find other terminals',
          logout: 'Logout',
          history: 'HISTORY',
          logout_2: 'LOGOUT',
          renew: 'Renew ',
          events: 'CONTACT US FOR EVENTS',
          maps_menu: 'TERMINALS MAP',
          here: 'here',
          table_footnote: '*You are renting only one power bank and its energy may not be available for the whole renting time.',
          by: ' by ',
          table_title: 'Charged Values',
          of: ' of ',
          text_table_redraw: 'Withdraw your power bank at:',
          text_table_parks: 'Parks:',
          text_table_charges_only: 'You will be charged only ',
          text_table_other_locations: 'Other locations:',
          text_table_ischarged: '- Charged ',
          text_table_after_period: ', after this period, you will be charged ',
          feedback_thanks: 'Thanks for the feedback!',
          close: 'Close',
          send: 'Send',
          skip: 'Skip',
          what_happened: 'What happened?',
          battery_not_removed: 'Battery was not removed.',
          inform_token: 'Token must be informed.',
          token_contain: 'Token must contain only numbers or only letters.',
          token_size: 'Token must contain 4 numbers or letters.'
        },
        return: {
          success: 'BATTERY WITHDRAWED SUCCESSFULLY!',
          free_time: 'Free Time Remaining',
          free_time_over:
            'Your free time is up! Please return the battery to any of our terminals.',
          time: 'Time using battery',
          price: 'Current Price',
          check_out: 'Check out ',
          price_table: 'Price List'
        }
      },
      history: {
        rent: 'Rent',
        plan: 'Plan: ',
        load_more: 'Load More',
        pending: 'RETURN PENDING'
      }
    }),
    new MLanguage('portuguese').create({
      confirm_account: {
        welcome: 'Confirmação de conta',
        waiting: 'Verificando conta...',
        confirmation_successful: 'Sua conta foi confirmada com sucesso',
        confirmation_failed: 'Ocorreu um erro ao confirmar a sua conta',
        unspected_error: 'Ocorreu um erro inesperado'
      },
      login: {
        battery_almost_over: 'BATERIA QUASE ACABANDO?!',
        fast_login: 'FAÇA LOGIN PARA RETIRADA RÁPIDA',
        or: 'OU',
        create_account: 'CRIAR UMA CONTA NOVCRIAR UMA CONTA NOVA',
        sign_in: 'Entre na sua conta',
        sign_in_button: 'Entrar',
        email_address: 'Email',
        password: 'Senha',
        forgot_password: 'Esqueceu a senha?',
        dont_have_account: 'Ainda não tem uma conta?',
        register_email: 'Cadastrar com email',
        register_social: 'Cadastrar com rede social',
        register_with_email: 'Acessar pelo email',
        login_with_code: 'Já possui o código?',
        click_here: 'Clique aqui',
        facebook_btn_text: 'Continuar com o Facebook',
        locale: 'pt_BR',
        whatsapp_msg: 'https://api.whatsapp.com/send/?phone=5521967445033&text=Oi!%20Preciso%20de%20ajuda%20-%20E2G',
        find_us: 'Onde estamos ',
        title_rent: 'Alugue um carregador portátil',
        verify_mail: '*Verifique o código na sua caixa de email. Se não encontrar, busque na caixa de spam'
      },
      sing_up: {
        submit: 'Acessar',
        sing_up: 'Acesso pelo email',
        email_address: 'Email',
        password: 'Senha',
        password_confirmation: 'Confirmar Senha',
        agree_user_terms: ' Li e concordo com os',
        user_terms: 'Termos de Uso',
        already_have_account: 'Já tem uma conta cadastrada?',
        already_have_social_account: 'Cadastre com sua rede social',
        mandatory_user_terms: 'Você deve concordar com os Termos de Uso',
        password_doesnt_match: 'A senha não confere com a confirmação',
        invalid_email: 'Email inválido',
        required_email: 'Email é obrigatório',
        email_verification: 'Verifique seu EMAIL para acessar a conta. Se não encontrar, busque na caixa de spam',
        title_open_mail_modal: 'Validação'
      },
      forgot_password: {
        forgot_password: 'Esqueceu a senha?',
        email: 'Email',
        remember: 'Enviar',
        success: 'Enviamos um email com um link para redefinir sua senha',
        failed: 'Não encontramos um usuário com este Email',
        redirect_msg: 'Você será redirecionado para a página de login em 5 segundos',
        invalid_email: 'Email inválido',
        required_email: 'Email é obrigatório'
      },
      map: {
        place_open: 'Aberto',
        place_closed: 'Fechado',
        available_bateries: 'baterias disponíveis',
        available_slots: 'portas disponíveis',
        close_hour: 'Fecha às',
        open_hour: 'Abre às',
        hour: 'pt',
        my_location: 'Minha Localização',
        phone: 'SMARTPHONE',
        ebike: 'E-BIKE',
        all: 'TODOS',
        terminal_type: 'Pontos para: '
      },
      home: {
        take: {
          add_card: 'CADASTRE SEU CARTÃO',
          no_charge:
            'Cadastre o seu cartão de crédito para retirar uma bateria portátil.',
          card_number: 'Número do Cartão',
          valid_through: 'Validade',
          name: 'Nome Impresso',
          passport: 'Passaporte',
          use_passport: 'Usar Passaporte',
          use_cpf: 'Usar CPF',
          take_select: 'Selecione o tipo de cabo, ',
          take_info: 'ele já vem embutido na bateria.',
          cable_type: 'Escolha o tipo de cabo',
          scan_qr: 'Escaneie o QR Code',
          type_token: 'Ou digite o código de 4 dígitos que fica abaixo do QR code na frente do terminal.',
          terminal_token: 'Token da Máquina',
          take_battery: 'RETIRAR BATERIA',
          agreed: 'Ao RETIRAR BATERIA você concorda com os valores exibidos ',
          price_table: 'Tabela de Preços',
          credit_cards: 'CARTÕES DE CRÉDITO',
          manage: 'Gerencie suas formas de pagamento',
          standard: 'Padrão',
          delete_title: 'Excluir Cartão',
          delete_text: 'Excluir cartão com final ',
          cancel: 'CANCELAR',
          ok: 'CONFIRMAR',
          just_ok: 'OK',
          standard_title: 'Cartão Padrão',
          standard_text: 'Tornar padrão o cartão com final ',
          set_standart_credit_card_not_possible: 'Ocorreu um erro ao atualizar o método de pagamento padrão. Por favor, tente novamente',
          proccessing: 'Processando Retirada',
          slot: 'EMPURRE, em seguida PUXE a bateria indicada pelo LED piscando',
          charger: 'O App do charger deve estar indicando que a bateria está liberada para o aluguel',
          verify_terminal: 'Verificando Terminal',
          register_card: 'Registrando Cartão de Crédito',
          far:
            'Notamos que o terminal solicitado se encontra longe do seu dispositivo. Tem certeza que deseja continuar?',
          credit_reservation:
            'Será efetuada uma reserva de saldo em seu cartão de acordo com a tabela de preços. Se a bateria não for retirada a reserva será automaticamente cancelada.',
          dont_ask: 'Não perguntar novamente',
          campaign:
            'Ao fazer uso do tempo promocional você concorda em receber informações do patrocinador',
          no_free: 'Retirar sem tempo grátis',
          update: 'Atualizando permissões',
          on_demand: 'ALUGUEL SOB DEMANDA',
          subscription: 'PLANOS POR ASSINATURA',
          understand: 'ENTENDI',
          free_time_title: 'Próxima Retirada',
          free: 'grátis',
          sponsored_by: 'Oferecimento',
          at: 'no',
          only_at:
            'Para ter acesso a localização de nossos terminais, ',
          click_here: 'entre aqui!',
          other_terminals: 'Encontre outros pontos',
          logout: 'Sair',
          history: 'HISTÓRICO',
          logout_2: 'SAIR',
          renew: 'Renovação ',
          events: 'CONTATE-NOS PARA EVENTOS',
          maps_menu: 'MAPA DOS PONTOS',
          here: 'aqui',
          table_footnote: '*O aluguel é para apenas uma bateria portátil e sua carga pode não durar por todo o período do aluguel.',
          by: ' por ',
          table_title: 'Valores cobrados',
          of: ' de ',
          text_table_redraw: 'Retire sua bateria portátil* em:',
          text_table_parks: 'Parques:',
          text_table_charges_only: '- São cobradas apenas ',
          text_table_other_locations: 'Demais pontos:',
          text_table_ischarged: '- É cobrado ',
          text_table_after_period: ', após esse período são cobradas ',
          feedback_thanks: 'Obrigado pela resposta!',
          close: 'Fechar',
          send: 'Enviar',
          skip: 'Pular',
          what_happened: 'O que ocorreu?',
          battery_not_removed: 'Bateria não foi retirada.',
          inform_token: 'Token deve ser informado.',
          token_contain: 'Token deve conter somente números ou somente letras',
          token_size: 'Token deve conter 4 números ou 4 letras.'
        },
        return: {
          success: 'BATERIA RETIRADA COM SUCESSO!',
          free_time: 'Tempo Grátis Restante',
          free_time_over:
            'Seu tempo grátis acabou! Por favor, retorne a bateria em algum de nossos terminais.',
          time: 'Tempo com a Bateria',
          price: 'Valor Atual',
          check_out: 'Quero ver a ',
          price_table: 'Tabela de Preços'
        }
      },
      history: {
        rent: 'Aluguéis',
        plan: 'Plano: ',
        load_more: 'Carregar Mais',
        pending: 'DEVOLUÇÃO PENDENTE'
      }
    })
  ]
})
